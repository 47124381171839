export const Routes = {
  // pages
  Presentation: { path: "/", permission: "" },
  DashboardOverview: { path: "/dashboard", permission: "F1L" },
  Transactions: { path: "/transactions", permission: "TR_BROWSE" },
  Settings: { path: "/settings", permission: "" },
  Login: { path: "/auth/login", permission: "" },
  //
  ForgotPassword: { path: "/parts/forgot-password", permission: "" },
  ResetPassword: { path: "/parts/reset-password", permission: "" },
  Lock: { path: "/parts/lock", permission: "" },
  NotFound: { path: "/parts/404", permission: "" },
  NotAuthorized: { path: "/parts/401", permission: "" },
  ServerError: { path: "/parts/500", permission: "" },
  //
  Devices: { path: "/devices/browse", permission: "DT_BROWSE" },
  DeviceImages: { path: "/devices/:id/details", permission: "DT_READ" },
  EditDevice: { path: "/devices/:id/edit", permission: "DT_EDIT" },
  NewDevice: { path: "/devices/create", permission: "DT_CREATE" },
  UploadDevice: { path: "/devices/import", permission: "DT_BROWSE" },
  //
  DeviceCategory: { path: "/devices/categories", permission: "DC_BROWSE" },
  NewDeviceCategory: {
    path: "/devices/categories/create",
    permission: "DC_CREATE",
  },
  DeviceCategoryDetails: {
    path: "/devices/categories/:id/details",
    permission: "DC_READ",
  },
  //
  Stores: { path: "/partners/stores", permission: "ST_BROWSE" },
  StoresDetails: {
    path: "/partners/stores/:id/details",
    permission: "ST_READ",
  },
  CreateStore: { path: "/partners/stores/create", permission: "ST_CREATE" },
  EditStore: { path: "/partners/stores/:id/edit", permission: "ST_EDIT" },
  //
  AllLoans: { path: "/transactions", permission: "TR_BROWSE" },
  //
  Clients: { path: "/clients/browse", permission: "IC_BROWSE" },
  CreateClient: { path: "/clients/create", permission: "IC_CREATE" },
  ClientDetails: { path: "/clients/:id/details", permission: "IC_READ" },
  //
  Users: { path: "/users/browse", permission: "US_BROWSE" },
  CreateUser: { path: "/users/create-user", permission: "US_CREATE" },
  EditUser: { path: "/users/:id/edit", permission: "US_EDIT" },
  ViewUser: { path: "/users/:username/details", permission: "US_READ" },
  MarksAsRepresentative: {
    path: "/users/:username/mark-as-representative",
    permission: "US_CREATE",
  },
  BrowseRepresentative: {
    path: "/users/representatives",
    permission: "US_BROWSE",
  },
  //
  ActiveLoans: { path: "/loans/", permission: "LO_BROWSE" },
  //
  LoansPartners: { path: "/loans/partners", permission: "PP_BROWSE" },
  //
  AllPayments: { path: "/transactions", permission: "TR_BROWSE" },
  MtnLoanRequests: {
    path: "/reports/telcos-loans-requested",
    permission: "TR_BROWSE",
  },
  //
  // Plans: {path: "/plans/settings", permission: "PL_BROWSE"},
  EditPlans: { path: "/plans/:id/settings/edit", permission: "PL_EDIT" },
  ViewPlans: { path: "/plans/:id/settings", permission: "PL_READ" },
  //
  Partners: { path: "/partners/:code", permission: "PA_BROWSE" },
  AddPartner: { path: "/partners/:code/create", permission: "PA_CREATE" },
  ViewPartner: { path: "/partners/:code/:id/view", permission: "PA_READ" },
  EditPartner: { path: "/partners/:code/:id/edit", permission: "PA_EDIT" },
  //
  GroupsRoles: { path: "/users/roles/groups", permission: "GR_BROWSE" },
  AddGroupRoles: {
    path: "/users/roles/groups/create",
    permission: "GR_CREATE",
  },
  CreateRole: {
    path: "/users/roles/permissions/create",
    permission: "RO_CREATE",
  },
  AssignRolesToGroup: {
    path: "/users/roles/:id/groups/",
    permission: "RO_CREATE",
  },
  //
  Corporates: { path: "/corporates/browse", permission: "CC_BROWSE" },
  CreateCorporate: { path: "/corporates/create", permission: "CC_CREATE" },
  CorporateDetails: { path: "/corporates/:id/details", permission: "CC_READ" },
  UploadTemplate: { path: "/corporates/template", permission: "CC_CREATE" },
  //
  Tickets: { path: "/service-center/tickets", permission: "TQ_BROWSE" },
  PushNotification: {
    path: "/service-center/notifications",
    permission: "PN_BROWSE",
  },
  FAQ: { path: "/service-center/faq", permission: "" },
  //
  SimSwap: { path: "/utilities/device-searching", permission: "CAN_SWAP_SIM" },
  PhoneSwap: { path: "/utilities/phone-swap", permission: "CAN_SWAP_PHONE" },
  LegibilityCheck: {
    path: "/utilities/legibility-check",
    permission: "IC_KYC_READ",
  },
  Provisioning: { path: "/utilities/provisioning", permission: "IC_D_BUNDLE" },
  //
  C360ForceUpdate: {
    path: "/utilities/c360-force-update",
    permission: "IC_D_UPDATE",
  },
  ManualMtnBundle: {
    path: "/utilities/manual-mtn-bundle",
    permission: "IC_D_BUNDLE",
  },

  //Payment Collection
  PaymentCollection: {
    path: "/utilities/payment-collection",
    permission: "PAYMENT_COLLECTION",
  },

  // Manual Repayment
  ManualReplayment: {
    path: "/manual-repayment/upload",
    permission: "MANUAL_REPAYMENT",
  },
  CheckManualRepayment: {
    path: "/manual-repayment/check",
    permission: "MANUAL_REPAYMENT",
  },

  // Customer Paylog
  CustomerPaymentLog: {
    path: "/customer-payment-log/upload",
    permission: "IT_SPPRT",
  },
  CustomerPaymentLogMultiple: {
    path: "/customer-payment-log/upload-multiple",
    permission: "IT_SPPRT",
  },
  CheckCustomerPaymentLog: {
    path: "/customer-payment-log/check",
    permission: "IT_SPPRT",
  },
  FetchCustomerPaymentLog: {
    path: "/customer-payment-log/fetch-all",
    permission: "IT_SPPRT",
  },

  // Customer Graduation
  CustomerGraduationProcessing: {
    path: "/process-customer-graduation",
    permission: "IT_SPPRT",
  },
  CustomerGraduationFetch: {
    path: "/fetch-graduating-customers",
    permission: "IT_SPPRT",
  },
  CustomerGraduationVerifyGraduate: {
    path: "/verify-graduate-customer",
    permission: "IT_SPPRT",
  },

  //Support Issues
  CommonFixes: { path: "/common-fix", permission: "IT_SPPRT" },
  MissingCustomerRecordIssue: {
    path: "/missing-customer-records",
    permission: "IT_SPPRT",
  },
  TrasanctionStatus: { path: "/transaction-status", permission: "IT_SPPRT" },
  PendingTransactions: {
    path: "/pending-transactions",
    permission: "IT_SPPRT",
  },
  CheckPayment: {
    path: "/check-payment",
    permission: "IT_SPPRT",
  },
  DeviceDetails: {
    path: "/imei-details",
    permission: "IT_SPPRT",
  },

  //Loan Cancellation
  LoanInitiation: {
    path: "/loans/loan-initiate",
    permission: "IT_SPPRT",
  },

  LoanClosures: {
    path: "/loans/loan-closures",
    permission: "IT_SPPRT",
  },

  LoanRepayment: {
    path: "/loans/loan-repayment",
    permission: "REPAYMENT_VIEW",
  }


};


