import React, {useEffect, useState} from "react";
import SimpleBar from "simplebar-react";
import {Link, useLocation} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowCircleRight,
    faBell,
    faChartPie,
    faHandshake,
    faHandsHelping,
    faHome,
    faHospital,
    faInfoCircle,
    faMobile, faMoneyBill,
    faMoneyCheck,
    faReceipt,
    faSearch,
    faStore,
    faTicketAlt,
    faTimes,
    faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import {
    Accordion,
    Badge,
    Button,
    Image,
    Nav,
    Navbar,
} from "@themesberg/react-bootstrap";

import {Routes} from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import Profile3 from "../assets/img/team/profile-picture-10.png";
import {faFan} from "@fortawesome/free-solid-svg-icons/faFan";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faCogs} from "@fortawesome/free-solid-svg-icons/faCogs";
import {faBusinessTime} from "@fortawesome/free-solid-svg-icons/faBusinessTime";
import {faFileUpload} from "@fortawesome/free-solid-svg-icons/faFileUpload";
import {faListAlt} from "@fortawesome/free-regular-svg-icons/faListAlt";
import {connect} from "react-redux";
import authorization from "../helpers/authorization";
import {
    faQuestionCircle,
    faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import useRoute from "../helpers/useRoute";

const Sidebar = (props) => {
    const location = useLocation();
    const {pathname} = location;
    const [show, setShow] = useState(false);
    const [username, setUsername] = useState(props.user ? props.user.name : "");
    const showClass = show ? "show" : "";

    useEffect(() => {
        if (props.user && username === "") {
            const username = props.user ? props.user.name : "";
            setUsername(`${username} `);
        }
    }, [setUsername, props, username]);

    const onCollapse = () => setShow(!show);

    const CollapsableNavItem = (props) => {
        const {eventKey, title, icon, children = null} = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
        const roles = props.roles ?? [];
        if (!authorization(roles)) {
            return <></>;
        }
        //
        return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Button
                        as={Nav.Link}
                        className="d-flex justify-content-between align-items-center"
                    >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon}/>{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
                    </Accordion.Button>
                    <Accordion.Body className="multi-level">
                        <Nav className="flex-column">{children}</Nav>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };

    const NavItem = (props) => {
        const {
            title,
            link,
            external,
            target,
            icon,
            image,
            badgeText,
            badgeBg = "secondary",
            badgeColor = "primary",
        } = props;
        const classNames = badgeText
            ? "d-flex justify-content-start align-items-center justify-content-between"
            : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? {href: link} : {as: Link, to: link};
        //
        const {role} = props;
        //
        return authorization(role) ? (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
                <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon}/>{" "}
              </span>
            ) : null}
              {image ? (
                  <Image
                      src={image}
                      width={20}
                      height={20}
                      className="sidebar-icon svg-icon"
                  />
              ) : null}

              <span className="sidebar-text">{title}</span>
          </span>
                    {badgeText ? (
                        <Badge
                            pill
                            bg={badgeBg}
                            text={badgeColor}
                            className="badge-md notification-count ms-2"
                        >
                            {badgeText}
                        </Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        ) : (
            <></>
        );
    };

    return (
        <>
            <Navbar
                expand={false}
                collapseOnSelect
                variant="dark"
                className="navbar-theme-primary px-4 d-md-none"
            >
                <Navbar.Brand
                    className="me-lg-5"
                    as={Link}
                    to={Routes.DashboardOverview.path}
                >
                    <Image src={ReactHero} className="navbar-brand-light"/>
                </Navbar.Brand>
                <Navbar.Toggle
                    as={Button}
                    aria-controls="main-navbar"
                    onClick={onCollapse}
                >
                    <span className="navbar-toggler-icon"/>
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar
                    className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
                >
                    <div className="sidebar-inner px-2 pt-3">
                        <div
                            className="user-card d-flex align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar lg-avatar me-4">
                                    <Image
                                        src={Profile3}
                                        className="card-img-top rounded-circle border-white"
                                    />
                                </div>
                                <div className="d-block">
                                    <h6>
                                        Hi,{" "}
                                        {username ? username.toString().toLocaleUpperCase() : ""}
                                    </h6>
                                </div>
                            </div>
                            <Nav.Link
                                className="collapse-close d-md-none"
                                onClick={onCollapse}
                            >
                                <FontAwesomeIcon icon={faTimes}/>
                            </Nav.Link>
                        </div>
                        <Nav className="flex-column pt-3 my-1 pt-md-0">
                            <NavItem
                                title="Dashboard"
                                link={Routes.DashboardOverview.path}
                                icon={faHome}
                                role={Routes.DashboardOverview.permission}
                            />
                            <CollapsableNavItem
                                eventKey="devices/"
                                title="Devices Types"
                                icon={faMobile}
                                roles={[
                                    `${Routes.Devices.permission}`,
                                    `${Routes.DeviceCategory.permission}`,
                                    `${Routes.UploadDevice.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Devices Types"
                                    link={Routes.Devices.path}
                                    icon={faFan}
                                    role={Routes.Devices.permission}
                                />
                                <NavItem
                                    title="Device Categories"
                                    link={Routes.DeviceCategory.path}
                                    icon={faFan}
                                    role={Routes.DeviceCategory.permission}
                                />
                                {/*<NavItem title="Import Devices" link={Routes.UploadDevice.path} icon={faFan}*/}
                                {/*         role={Routes.UploadDevice.permission}/>*/}
                            </CollapsableNavItem>
                            <NavItem
                                title="Individual Clients"
                                link={Routes.Clients.path}
                                icon={faUserFriends}
                                role={Routes.Clients.permission}
                            />
                            <CollapsableNavItem
                                eventKey="corporates/"
                                title="Corporates Clients"
                                icon={faBusinessTime}
                                roles={[
                                    `${Routes.Corporates.permission}`,
                                    `${Routes.UploadTemplate.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Corporates"
                                    link={Routes.Corporates.path}
                                    icon={faListAlt}
                                    role={Routes.Corporates.permission}
                                />
                                <NavItem
                                    title="Upload Template"
                                    link={Routes.UploadTemplate.path}
                                    icon={faFileUpload}
                                    role={Routes.UploadTemplate.permission}
                                />
                            </CollapsableNavItem>
                            <CollapsableNavItem
                                eventKey="partners/"
                                title="Partners"
                                icon={faHandshake}
                                roles={[
                                    `${Routes.Stores.permission}`,
                                    `${Routes.Partners.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Stores"
                                    link={Routes.Stores.path}
                                    icon={faStore}
                                    role={Routes.Stores.permission}
                                />
                                <NavItem
                                    title="Banks"
                                    link={useRoute(Routes.Partners.path, {code: "banks"})}
                                    icon={faHandshake}
                                    role={Routes.Partners.permission}
                                />
                                <NavItem
                                    title="Insurances"
                                    link={useRoute(Routes.Partners.path, {code: "insurances"})}
                                    icon={faHospital}
                                    role={Routes.Partners.permission}
                                />
                                <NavItem
                                    title="Telcos"
                                    link={useRoute(Routes.Partners.path, {code: "telcos"})}
                                    icon={faArrowCircleRight}
                                    role={Routes.Partners.permission}
                                />
                            </CollapsableNavItem>
                            {/*<NavItem title="Plans" link={Routes.Plans.path} icon={faList}*/}
                            {/*         role={Routes.Plans.permission}/>*/}
                            {/* <NavItem
                title="Loans"
                link={Routes.ActiveLoans.path}
                icon={faMoneyCheck}
                role={Routes.ActiveLoans.permission}
              /> */}
                            <CollapsableNavItem
                                eventKey={"loans/"}
                                title={"Loans"}
                                icon={faMoneyCheck}
                                roles={[Routes.ActiveLoans.permission]}
                            >
                                <NavItem
                                    title="All Loans"
                                    link={Routes.ActiveLoans.path}
                                    icon={faMoneyCheck}
                                    role={Routes.ActiveLoans.permission}
                                />
                                <NavItem
                                    title="Initiate Loan Closure"
                                    link={Routes.LoanInitiation.path}
                                    icon={faMoneyCheck}
                                    role={Routes.LoanInitiation.permission}
                                />
                                <NavItem
                                    title="Pending Loan Closures"
                                    link={Routes.LoanClosures.path}
                                    icon={faMoneyCheck}
                                    role={Routes.LoanClosures.permission}
                                />
                                <NavItem
                                    title="Loan Re-Payment"
                                    link={Routes.LoanRepayment.path}
                                    icon={faMoneyBill}
                                    role={Routes.LoanRepayment.permission}
                                />
                            </CollapsableNavItem>
                            <CollapsableNavItem
                                eventKey={"reports/"}
                                title={"Reports"}
                                icon={faReceipt}
                                roles={[Routes.MtnLoanRequests.permission]}
                            >
                                <NavItem
                                    title="MTN Loans"
                                    link={Routes.MtnLoanRequests.path}
                                    icon={faReceipt}
                                    role={Routes.MtnLoanRequests.permission}
                                />
                            </CollapsableNavItem>
                            <NavItem
                                title="Accounting"
                                link={Routes.AllPayments.path}
                                icon={faChartPie}
                                role={Routes.AllPayments.permission}
                            />
                            <CollapsableNavItem
                                eventKey="utilities/"
                                title="Utilities"
                                icon={faCogs}
                                roles={[
                                    `${Routes.SimSwap.permission}`,
                                    `${Routes.LegibilityCheck.permission}`,
                                    `${Routes.Provisioning.permission}`,
                                    `${Routes.PaymentCollection.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Device Search"
                                    link={Routes.SimSwap.path}
                                    icon={faSearch}
                                    role={Routes.SimSwap.permission}
                                />
                                <NavItem
                                    title="Legibility Check"
                                    link={Routes.LegibilityCheck.path}
                                    icon={faQuestionCircle}
                                    role={Routes.LegibilityCheck.permission}
                                />
                                <NavItem
                                    title="Provissioning"
                                    link={Routes.Provisioning.path}
                                    icon={faPhoneSlash}
                                    role={Routes.Provisioning.permission}
                                />
                                <NavItem
                                    title="Payment Collection"
                                    link={Routes.PaymentCollection.path}
                                    icon={faMoneyBill}
                                    role={Routes.PaymentCollection.permission}
                                />
                            </CollapsableNavItem>
                            <CollapsableNavItem
                                eventKey="service-center/"
                                title="Service Center"
                                icon={faHandsHelping}
                                roles={[
                                    `${Routes.PushNotification.permission}`,
                                    `${Routes.Tickets.permission}`,
                                    `${Routes.FAQ.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Push Notifications"
                                    link={Routes.PushNotification.path}
                                    icon={faBell}
                                    role={Routes.PushNotification.permission}
                                />
                                <NavItem
                                    title="Tickets"
                                    link={Routes.Tickets.path}
                                    icon={faTicketAlt}
                                    role={Routes.Tickets.permission}
                                />
                                <NavItem
                                    title={"FAQ"}
                                    link={Routes.FAQ.path}
                                    role={Routes.FAQ.permission}
                                    icon={faQuestionCircle}
                                />
                            </CollapsableNavItem>

                            <CollapsableNavItem
                                eventKey="support_issues/"
                                title="Support Issues"
                                icon={faInfoCircle}
                                roles={[
                                    `${Routes.CommonFixes.permission}`,
                                    `${Routes.TrasanctionStatus.permission}`,
                                    `${Routes.PendingTransactions.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Common Fixes"
                                    link={Routes.CommonFixes.path}
                                    icon={faFan}
                                    role={Routes.CommonFixes.permission}
                                />
                                <NavItem
                                    title="Transaction Status"
                                    link={Routes.TrasanctionStatus.path}
                                    icon={faListAlt}
                                    role={Routes.TrasanctionStatus.permission}
                                />
                                <NavItem
                                    title="Pending Transactions"
                                    link={Routes.PendingTransactions.path}
                                    icon={faListAlt}
                                    role={Routes.PendingTransactions.permission}
                                />
                                <NavItem
                                    title="Payments"
                                    link={Routes.CheckPayment.path}
                                    icon={faListAlt}
                                    role={Routes.CheckPayment.permission}
                                />
                                <NavItem
                                    title="Device Details"
                                    link={Routes.DeviceDetails.path}
                                    icon={faListAlt}
                                    role={Routes.DeviceDetails.permission}
                                />
                                {/* <NavItem
                  title="Missing records"
                  link={Routes.MissingCustomerRecordIssue.path}
                  icon={faListAlt}
                  role={Routes.UploadDevice.permission}
                /> */}
                            </CollapsableNavItem>

                            <CollapsableNavItem
                                eventKey="users/"
                                title="Users & Roles"
                                icon={faUsers}
                                roles={[
                                    `${Routes.Users.permission}`,
                                    `${Routes.GroupsRoles.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="All Users"
                                    link={Routes.Users.path}
                                    icon={faUsers}
                                    role={Routes.Users.permission}
                                />
                                <NavItem
                                    title="User Groups"
                                    link={Routes.GroupsRoles.path}
                                    icon={faUserFriends}
                                    role={Routes.GroupsRoles.permission}
                                />
                            </CollapsableNavItem>

                            {/* Newly added  */}
                            <CollapsableNavItem
                                eventKey="customer_paymentLog/"
                                title="Customers Paylog"
                                icon={faUserFriends}
                                roles={[
                                    `${Routes.CustomerPaymentLog.permission}`,
                                    `${Routes.CustomerPaymentLogMultiple.permission}`,
                                    `${Routes.CheckCustomerPaymentLog.permission}`,
                                    `${Routes.FetchCustomerPaymentLog.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Insert Single"
                                    link={Routes.CustomerPaymentLog.path}
                                    icon={faFileUpload}
                                    role={Routes.CustomerPaymentLog.permission}
                                />
                                <NavItem
                                    title="Insert Multiple"
                                    link={Routes.CustomerPaymentLogMultiple.path}
                                    icon={faHospital}
                                    role={Routes.CustomerPaymentLogMultiple.permission}
                                />
                                <NavItem
                                    title="Fetch Records"
                                    link={Routes.FetchCustomerPaymentLog.path}
                                    icon={faTicketAlt}
                                    role={Routes.FetchCustomerPaymentLog.permission}
                                />
                                <NavItem
                                    title="Check Status"
                                    link={Routes.CheckCustomerPaymentLog.path}
                                    icon={faSearch}
                                    role={Routes.CheckCustomerPaymentLog.permission}
                                />
                            </CollapsableNavItem>
                            <CollapsableNavItem
                                eventKey="customer_graduation/"
                                title="Customers Graduation"
                                icon={faUserFriends}
                                roles={[
                                    `${Routes.CustomerGraduationProcessing.permission}`,
                                    `${Routes.CustomerGraduationFetch.permission}`,
                                    `${Routes.CustomerGraduationVerifyGraduate.permission}`,
                                ]}
                            >
                                <NavItem
                                    title="Process Graduation"
                                    link={Routes.CustomerGraduationProcessing.path}
                                    icon={faFan}
                                    role={Routes.CustomerGraduationProcessing.permission}
                                />
                                <NavItem
                                    title="Fetch Grad Cust.."
                                    link={Routes.CustomerGraduationFetch.path}
                                    icon={faListAlt}
                                    role={Routes.CustomerGraduationFetch.permission}
                                />
                            </CollapsableNavItem>
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};
//
const mapStateProps = (state) => {
    const {user, roles, isLoggedIn} = state.authentication;
    return {user, roles, isLoggedIn};
};
export default connect(mapStateProps, {})(Sidebar);
