import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Routes} from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Settings from "./Settings";
import Devices from "./device-types/Devices";
import Stores from "./stores/Browse";
import CreateStore from "./stores/Edit-Add";
import StoreView from "./stores/StoreView";
import AllLoans from "./tables/AllLoans";
import Users from "./users/Users";
import AllocatedLoans from "./loans/AllocatedLoans";
import AllPayments from "./tables/AllPayments";
import Login from "./parts/login";
import CreateUser from "./users/CreateUser";
import NewDevice from "./device-types/NewDevice";
import ForgotPassword from "./parts/ForgotPassword";
import ResetPassword from "./parts/ResetPassword";
import Lock from "./parts/Lock";
import NotFoundPage from "./parts/NotFound";
import ServerError from "./parts/ServerError";
import DeviceDetails from "./device-types/DeviceDetails";
import BrowseRepresentative from "./representatives/BrowseRepresentative";
import MarkAsRepresentative from "./representatives/MarkAsRepresentative";
import EditUser from "./users/EditUser";
import Clients from "./clients/Clients";
import CreateClient from "./clients/CreateClient";
import DeviceCategory from "./device-categories/DeviceCategory";
import CreateCategory from "./device-categories/CreateCategory";
import ImportDeviceTypes from "./device-types/ImportDeviceTypes";
import Partners from "./partners/Partners";
import AddPartner from "./partners/AddPartner";
import LoansPartners from "./partners-plans/LoansPartners";
import EditDevice from "./device-types/EditDevice";
import ClientDetails from "./clients/ClientDetails";
import RolesGroups from "./roles/RoleGroups";
import CreateRoleGroup from "./roles/CreateRoleGroup";
import Roles from "./roles/Roles";
import Corporates from "./corporates/Corporates";
import UploadTemplate from "./corporates/UploadTemplate";
import CreateCorporate from "./corporates/CreateCorporate";
import DetailCorporate from "./corporates/DetailCorporate";
import Tickets from "./tickets/Tickets";
import PushNotification from "./push-notification/PushNotification";
import SimSwap from "./utilities/SimSwap";
import PhoneSwap from "./utilities/PhoneSwap";
import UserDetail from "./users/UserDetail";
import CreatePermission from "./roles/CreatePermission";
import Faq from "./faq/Faq";
import LegibilityCheck from "./utilities/LegibilityCheck";

import Preloader from "../components/Preloader";

import ProtectedRoute from "../components/ProtectedRoute";
import NotAuthorized from "./parts/NotAuthorized";
import Transactions from "./transactions/transactions";
import DeviceTypeCategory from "./device-categories/Category";
import PartnerView from "./partners/PartnerView";
import TelcosLoanRequested from "./reports/TelcosLoanRequested";
import Provisioning from "./utilities/Provisioning";
import ManualRepaymentUpload from "./manual_repayment/ManualRepaymentUpload";
import CheckRepaymentStatus from "./manual_repayment/CheckRepaymentStatus";
import CustomerPaymentUpload from "./customer_paymentLog/CustomerPaymentLog";
import CheckPaymentLogStatus from "./customer_paymentLog/CheckPaymentLogStatus";
import FetchPaymentLogStatus from "./customer_paymentLog/FetchPaymentLogStatus";
import CustomerPaymentLogMultiple from "./customer_paymentLog/CustomerPaymentLogMultiple";
import Alert from "../components/alert/Alert";
import {SnackbarProvider} from "notistack";
import {connect} from "react-redux";
import ProcessCustomerGraduation from "./customer_graduation/ProcessCustomerGraduation";
import FetchGradCustomers from "./customer_graduation/FetchGradCustomers";
import CommonFix from "./supports-issues/CommonFix";
import MissingCustomerRecoreds from "./supports-issues/MissingCustomerRecoreds";
import TransactionStatus from "./supports-issues/TransactionStatus";
import PendingTransactions from "./supports-issues/PendingTransactions";
import Payments from "./supports-issues/Payments";
import IMEIDeviceDetails from "./supports-issues/IMEIDeviceDetails";
import LoanInitiation from "./loans/LoanInitiation";
import ClosureLoans from "./loans/ClosureLoans";
import PaymentCollection from "./utilities/PaymentCollection";
import LoanRepayment from "./loans/LoanRepayment";

const RouteWithLoader = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => (
                <>
                    {" "}
                    <Preloader show={!loaded}/> <Component {...props} />{" "}
                </>
            )}
        />
    );
};

const HomePage = ({alerts}) => {
    const allAlerts = alerts?.alerts;
    const redirectUrl = alerts?.redirectUrl;

    console.log("alerts", alerts);

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <Switch>
                <RouteWithLoader
                    exact
                    path={Routes.Presentation.path}
                    component={Login}
                />
                <RouteWithLoader exact path={Routes.Login.path} component={Login}/>
                <RouteWithLoader
                    exact
                    path={Routes.ForgotPassword.path}
                    component={ForgotPassword}
                />
                <RouteWithLoader
                    exact
                    path={Routes.ResetPassword.path}
                    component={ResetPassword}
                />
                <RouteWithLoader exact path={Routes.Lock.path} component={Lock}/>
                <RouteWithLoader
                    exact
                    path={Routes.NotFound.path}
                    component={NotFoundPage}
                />
                <RouteWithLoader
                    exact
                    path={Routes.NotAuthorized.path}
                    component={NotAuthorized}
                />
                <RouteWithLoader
                    exact
                    path={Routes.ServerError.path}
                    component={ServerError}
                />

                {/* pages */}
                <ProtectedRoute
                    exact
                    path={Routes.DashboardOverview.path}
                    role={Routes.DashboardOverview.permission}
                    component={DashboardOverview}
                />
                <ProtectedRoute
                    exact
                    path={Routes.Transactions.path}
                    role={Routes.Transactions.permission}
                    component={Transactions}
                />
                <ProtectedRoute
                    exact
                    path={Routes.Settings.path}
                    role={Routes.CreateUser.permission}
                    component={Settings}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.Devices.path}
                    role={Routes.Devices.permission}
                    component={Devices}
                />
                <ProtectedRoute
                    exact
                    path={Routes.EditDevice.path}
                    role={Routes.EditDevice.permission}
                    component={EditDevice}
                />
                <ProtectedRoute
                    exact
                    path={Routes.DeviceImages.path}
                    role={Routes.DeviceImages.permission}
                    component={DeviceDetails}
                />
                <ProtectedRoute
                    exact
                    path={Routes.NewDevice.path}
                    role={Routes.NewDevice.permission}
                    component={NewDevice}
                />
                <ProtectedRoute
                    exact
                    path={Routes.UploadDevice.path}
                    role={Routes.UploadDevice.permission}
                    component={ImportDeviceTypes}
                />
                <ProtectedRoute
                    exact
                    path={Routes.DeviceCategory.path}
                    role={Routes.DeviceCategory.permission}
                    component={DeviceCategory}
                />
                <ProtectedRoute
                    exact
                    path={Routes.DeviceCategoryDetails.path}
                    role={Routes.DeviceCategoryDetails.permission}
                    component={DeviceTypeCategory}
                />
                <ProtectedRoute
                    exact
                    path={Routes.NewDeviceCategory.path}
                    role={Routes.NewDeviceCategory.permission}
                    component={CreateCategory}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.Stores.path}
                    role={Routes.Stores.permission}
                    component={Stores}
                />
                <ProtectedRoute
                    exact
                    path={Routes.StoresDetails.path}
                    role={Routes.StoresDetails.permission}
                    component={StoreView}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CreateStore.path}
                    role={Routes.CreateStore.permission}
                    component={CreateStore}
                />
                <ProtectedRoute
                    exact
                    path={Routes.EditStore.path}
                    role={Routes.EditStore.permission}
                    component={CreateStore}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.LoansPartners.path}
                    role={Routes.LoansPartners.permission}
                    component={LoansPartners}
                />
                <ProtectedRoute
                    exact
                    path={Routes.LoanInitiation.path}
                    role={Routes.LoanInitiation.permission}
                    component={LoanInitiation}
                />
                <ProtectedRoute
                    exact
                    path={Routes.LoanClosures.path}
                    role={Routes.LoanClosures.permission}
                    component={ClosureLoans}
                />
                <ProtectedRoute
                    exact
                    path={Routes.LoanClosures.path}
                    role={Routes.LoanClosures.permission}
                    component={AllLoans}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.Users.path}
                    role={Routes.Users.permission}
                    component={Users}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CreateUser.path}
                    role={Routes.CreateUser.permission}
                    component={CreateUser}
                />
                <ProtectedRoute
                    exact
                    path={Routes.EditUser.path}
                    role={Routes.EditUser.permission}
                    component={EditUser}
                />
                <ProtectedRoute
                    exact
                    path={Routes.ViewUser.path}
                    role={Routes.ViewUser.permission}
                    component={UserDetail}
                />
                <ProtectedRoute
                    exact
                    path={Routes.BrowseRepresentative.path}
                    role={Routes.BrowseRepresentative.permission}
                    component={BrowseRepresentative}
                />
                <ProtectedRoute
                    exact
                    path={Routes.MarksAsRepresentative.path}
                    role={Routes.MarksAsRepresentative.permission}
                    component={MarkAsRepresentative}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.GroupsRoles.path}
                    role={Routes.GroupsRoles.permission}
                    component={RolesGroups}
                />
                <ProtectedRoute
                    exact
                    path={Routes.AddGroupRoles.path}
                    role={Routes.AddGroupRoles.permission}
                    component={CreateRoleGroup}
                />
                <ProtectedRoute
                    exact
                    path={Routes.AssignRolesToGroup.path}
                    role={Routes.AssignRolesToGroup.permission}
                    component={Roles}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CreateRole.path}
                    role={Routes.CreateRole.permission}
                    component={CreatePermission}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.ActiveLoans.path}
                    role={Routes.ActiveLoans.permission}
                    component={AllocatedLoans}
                />
                <ProtectedRoute
                    exact
                    path={Routes.AllPayments.path}
                    role={Routes.AllPayments.permission}
                    component={AllPayments}
                />
                <ProtectedRoute
                    exact
                    path={Routes.MtnLoanRequests.path}
                    role={Routes.MtnLoanRequests.permission}
                    component={TelcosLoanRequested}
                />
                {/* ---- */}
                {/*<ProtectedRoute exact path={Routes.Plans.path}*/}
                {/*                role={Routes.Plans.permission}*/}
                {/*                component={Plans}/>*/}
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.Clients.path}
                    role={Routes.Clients.permission}
                    component={Clients}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CreateClient.path}
                    role={Routes.CreateClient.permission}
                    component={CreateClient}
                />
                <ProtectedRoute
                    exact
                    path={Routes.ClientDetails.path}
                    role={Routes.ClientDetails.permission}
                    component={ClientDetails}
                />
                {/* --- */}
                <ProtectedRoute
                    exact
                    path={Routes.Partners.path}
                    role={Routes.Partners.permission}
                    component={Partners}
                />
                <ProtectedRoute
                    exact
                    path={Routes.AddPartner.path}
                    role={Routes.AddPartner.permission}
                    component={AddPartner}
                />
                <ProtectedRoute
                    exact
                    path={Routes.EditPartner.path}
                    role={Routes.EditPartner.permission}
                    component={AddPartner}
                />
                <ProtectedRoute
                    exact
                    path={Routes.ViewPartner.path}
                    role={Routes.ViewPartner.permission}
                    component={PartnerView}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.Corporates.path}
                    role={Routes.Corporates.permission}
                    component={Corporates}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CorporateDetails.path}
                    role={Routes.CorporateDetails.permission}
                    component={DetailCorporate}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CreateCorporate.path}
                    role={Routes.CreateCorporate.permission}
                    component={CreateCorporate}
                />
                <ProtectedRoute
                    exact
                    path={Routes.UploadTemplate.path}
                    role={Routes.UploadTemplate.permission}
                    component={UploadTemplate}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.Tickets.path}
                    role={Routes.Tickets.permission}
                    component={Tickets}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.FAQ.path}
                    role={Routes.FAQ.permission}
                    component={Faq}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.PushNotification.path}
                    role={Routes.PushNotification.permission}
                    component={PushNotification}
                />
                {/* ---- */}
                <ProtectedRoute
                    exact
                    path={Routes.SimSwap.path}
                    role={Routes.SimSwap.permission}
                    component={SimSwap}
                />
                <ProtectedRoute
                    exact
                    path={Routes.PhoneSwap.path}
                    role={Routes.PhoneSwap.permission}
                    component={PhoneSwap}
                />
                <ProtectedRoute
                    exact
                    path={Routes.Provisioning.path}
                    role={Routes.Provisioning.permission}
                    component={Provisioning}
                />
                <ProtectedRoute
                    exact
                    path={Routes.LegibilityCheck.path}
                    role={Routes.LegibilityCheck.permission}
                    component={LegibilityCheck}
                />

                {/* Newly Added - starts  */}
                <ProtectedRoute
                    exact
                    path={Routes.ManualReplayment.path}
                    role={Routes.ManualReplayment.permission}
                    component={ManualRepaymentUpload}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CheckManualRepayment.path}
                    role={Routes.CommonFixes.permission}
                    component={CheckRepaymentStatus}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CustomerPaymentLog.path}
                    role={Routes.CommonFixes.permission}
                    component={CustomerPaymentUpload}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CustomerPaymentLogMultiple.path}
                    role={Routes.CommonFixes.permission}
                    component={CustomerPaymentLogMultiple}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CheckCustomerPaymentLog.path}
                    role={Routes.CommonFixes.permission}
                    component={CheckPaymentLogStatus}
                />
                <ProtectedRoute
                    exact
                    path={Routes.FetchCustomerPaymentLog.path}
                    role={Routes.CommonFixes.permission}
                    component={FetchPaymentLogStatus}
                />

                <ProtectedRoute
                    exact
                    path={Routes.CustomerGraduationProcessing.path}
                    role={Routes.CommonFixes.permission}
                    component={ProcessCustomerGraduation}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CustomerGraduationFetch.path}
                    role={Routes.CommonFixes.permission}
                    component={FetchGradCustomers}
                />

                <ProtectedRoute
                    exact
                    path={Routes.CustomerGraduationFetch.path}
                    role={Routes.CommonFixes.permission}
                    component={FetchGradCustomers}
                />

                <ProtectedRoute
                    exact
                    path={Routes.CommonFixes.path}
                    role={Routes.CommonFixes.permission}
                    component={CommonFix}
                />
                <ProtectedRoute
                    exact
                    path={Routes.TrasanctionStatus.path}
                    role={Routes.TrasanctionStatus.permission}
                    component={TransactionStatus}
                />
                <ProtectedRoute
                    exact
                    path={Routes.PendingTransactions.path}
                    role={Routes.PendingTransactions.permission}
                    component={PendingTransactions}
                />
                <ProtectedRoute
                    exact
                    path={Routes.CheckPayment.path}
                    role={Routes.CheckPayment.permission}
                    component={Payments}
                />
                <ProtectedRoute
                    exact
                    path={Routes.DeviceDetails.path}
                    role={Routes.DeviceDetails.permission}
                    component={IMEIDeviceDetails}
                />

                <ProtectedRoute
                    exact
                    path={Routes.MissingCustomerRecordIssue.path}
                    role={Routes.MissingCustomerRecordIssue.permission}
                    component={MissingCustomerRecoreds}
                />

                <ProtectedRoute
                    exact
                    path={Routes.PaymentCollection.path}
                    role={Routes.PaymentCollection.permission}
                    component={PaymentCollection}
                />

                <ProtectedRoute
                    exact
                    path={Routes.LoanRepayment.path}
                    role={Routes.LoanRepayment.permission}
                    component={LoanRepayment}
                />

                {/* Newly Added - ends  */}

                {/* ---- */}
                <Redirect to={Routes.NotFound.path}/>
            </Switch>
            {allAlerts.map((item, index) => (
                <Alert
                    key={index}
                    message={item.message}
                    variant={item.variant}
                    redirectUrl={redirectUrl}
                />
            ))}
        </SnackbarProvider>
    );
};

const mapStateToProps = (store) => {
    const alerts = store?.alerts;
    return {alerts};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
