import React, {Component} from "react";
import {Form, Nav, Pagination} from "@themesberg/react-bootstrap";

const PagingFooter = (props) => {
    const {totalElements, totalPages, currentPage, numberOfElements, last, first, onClick} = props;
    const pages = [...new Array(totalPages ?? 0)];

    return (
        <>
            <Nav>
                <Pagination className="mb-2 mb-lg-0">
                    <Pagination.Prev disabled={first} onClick={() => onClick((currentPage - 1))}>
                        Previous
                    </Pagination.Prev>
                    {pages.map(
                        (value, index) => <Pagination.Item key={index}
                                                           active={currentPage === index}
                                                           onClick={() => onClick(index)}>{(index + 1)}</Pagination.Item>
                    )}
                    <Pagination.Next disabled={last} onClick={() => onClick((currentPage + 1)) }>
                        Next
                    </Pagination.Next>
                </Pagination>
            </Nav>
            <small className="fw-bold">
                Showing <b>{numberOfElements}</b> out of <b>{totalElements}</b> entries
            </small>
        </>
    );
}

const PagingSize = (props) => {
    const {selectedSized, onChange} = props;
    return (
        <>
            <Form.Select aria-label="Page size" onChange={({target}) => onChange(target.value)}>
                <option selected disabled>Page Size</option>
                <option value="25" selected={selectedSized === 25}>25</option>
                <option value="100" selected={selectedSized === 100}>100</option>
                <option value="500" selected={selectedSized === 500}>500</option>
                <option value="1000" selected={selectedSized === 1000}>1000</option>
                <option value="5000" selected={selectedSized === 5000}>5000</option>
            </Form.Select>
        </>
    );
}

export {
    PagingFooter,
    PagingSize,
};
